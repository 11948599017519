import React from "react"
import ChurchIcon from "mdi-react/ChurchIcon"



import PageBody from "../../components/page-body/pageBody"
import SEO from "../../components/seo"

import Banner from "../../components/banner/banner"
import healthSmartBehaviorYear2 from "../../images/components/health-smart-behavior-year-2.jpg"
import healthSmartBehaviorYear2Body1 from "../../images/components/health-smart-behavior-year-2-body-1.jpg"
import healthSmartBehaviorYear2Body2 from "../../images/components/health-smart-behavior-year-2-body-2.jpg"

import BorderTitle from "./../../components/border-title/borderTitle"
import ComponentNavigator from "./../../components/component-navigator/componentNavigator"
import QuoteImages from "./../../components/quote-images/quoteImages"

import "./the-health-smart-behavior-program.scss"

var TheHealthSmartBehaviorProgram = props => (
  <>
    <SEO title="Home" keywords={["gatsby", "application", "react"]} />
    <div className="the-health-smart-behavior-program-page">
      <Banner
        src={healthSmartBehaviorYear2}
        title="The Health-Smart Behavior Program™ (called Health-Smart) "
        height="32.5rem"
        heightMobile="120vw"
        backgroundPosY="70%"
      />
      <PageBody className="page">
        <BorderTitle>
          The Health-Smart Behavior Program™ (called Health-Smart) 
        </BorderTitle>
        <div className="body">
          <img className="left" src={healthSmartBehaviorYear2Body1} alt="" /><h4>9-Session Group Discussion Program</h4> <ol> <li>• Designed to increase physical, mental, and spiritual health and wellbeing; social connections; and important aspects of food security, such as knowledge about buying, preparing, and eating healthy food.</li> <li>• 500 seniors at 24 sites in Health Zone 1 participate in nine group discussion sessions and attend interactive panel sessions with health professionals and smartphone training sessions.</li> </ol><h4>UF Health-Jacksonville Physician Prescription Program</h4> <ol> <li>• Providers at four UF Health-Jacksonville-affiliated clinics screen their patients for one or more social determents of health and prescribe the Health-Smart Program to those patients who likely would benefit from taking part in the program.</li> </ol><h4>Church-Based Food Pantry Program </h4> <ol> <li>• Twenty food pantries operated by faith-based organizations distribute free, healthy food to Health-Smart participants and other community members on a weekly basis.</li> </ol><img className="right" src={healthSmartBehaviorYear2Body2} alt="" /><h4>Physical Activity Sessions</h4> <ol> <li>• Program participants are provided with a list of free or reduced-cost physical activities that are available in the community. </li> </ol><h4>Online Food Security and Social Connection App</h4> <ol> <li>• A website that provides a way for program participants to get health information and resources, connect with each other, and have their health-related questions answered by health care professionals.</li> </ol>
        </div>
        
        <div className="navigation">
        <div className="grow-desktop"/>
          <ComponentNavigator
            next
            link="/components-year-two/pastor-mental-health-coach-training-program"
            icon={ChurchIcon}
            title="Pastor Mental Health Coach Training Program"
          />
        </div>
      </PageBody>
    </div>
  </>
  )

export default TheHealthSmartBehaviorProgram
